body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
}

.card {
  width: 300px;
  height: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 50px auto;
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
}

p {
  font-size: 24px;
  color: #666;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: #666;
  transition: color 0.3s ease;
}

a:hover {
  color: #ff0066;
}

.instagram {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff0066;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.instagram:hover {
  background-color: #fff;
  color: #ff0066;
}

.icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 5px;
}

.count {
  font-size: 36px;
  margin-top: 20px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .tools {
    display: none;
  }

  .tools-large {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  .emoji-large {
    font-size: 48px;
  }

  .count-large {
    font-size: 32px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: .App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

