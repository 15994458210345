@keyframes .App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tools {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.tool {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
}

.count {
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;
}

.tools-large {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 40px;
}

.emoji-large {
  font-size: 48px;
  margin-bottom: 10px;
}

.count-large {
  font-size: 30px;
  font-weight: bold;
}

.emoji-large {
  font-size: 72px;
  margin-bottom: 10px;
}